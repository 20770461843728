<template>
  <div>
    <v-data-table
      v-if="notifications.length"
      :headers="headers"
      :items="notifications"
      :sort-by="'datetime'"
      :sort-desc="true"
      dense class="table--has-pointer"
    >
      <template #[`item.actions`]="{ item }">
        <v-btn
          v-if="!item.isSent"
          icon
          @click.stop="$confirm($t('pushNotifications.confirmDelete')).then(res => res && removeScheduledNotification(item))"
        >
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <empty-list v-else :content="$t('organization.sections.pushNotifications')" />
  </div>
</template>

<script>
export default {
  name: 'History',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
  },
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data() {
    return {
    }
  },
  computed: {
    rawNotifications: ({ $store }) => $store.state.pushNotifications.dbHistory.map(notif => ({
      ...notif,
      isSent: notif.sendDate.seconds * 1000 < Date.now(),
    })),
    notifications({ rawNotifications, clusterId, projectId }) {
      return rawNotifications.filter(notif => {
        if (clusterId) return ['cluster', 'project'].includes(notif.scope) && notif.context.clusterId === clusterId
        if (projectId) return notif.scope === 'project' && notif.context.projectId === projectId
        return true
      }).map(notif => ({
        ...notif,
        title: notif.data.title,
        target: notif.scope === 'project'
          ? this.$t('pushNotifications.selectOptions.subscribersOf', [notif.context.projectName])
          : notif.scope === 'cluster'
            ? this.$t('pushNotifications.selectOptions.subscribersOf', [notif.context.clusterName])
            : this.$t('pushNotifications.selectOptions.all'),
        datetime: this.formatDatetime(new Date(notif.sendDate.seconds * 1000)),
        sender: notif.createdBy.name,
        status: notif.isSent ? this.$t('pushNotifications.status.sent') : this.$t('pushNotifications.status.scheduled'),
      }))
    },
    headers() {
      return [
        { text: this.$t('common.title'), value: 'title' },
        { text: this.$t('pushNotifications.target'), value: 'target' },
        { text: this.$t('common.datetime'), value: 'datetime' },
        { text: this.$t('pushNotifications.sender'), value: 'sender' },
        { text: this.$t('common.status'), value: 'status' },
        { text: this.$t('common.actions'), value: 'actions', filterable: false, sortable: false },
      ]
    },
  },
  methods: {
    formatDatetime(date) {
      return `${date.toISOString().slice(0, 10)} · ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    removeScheduledNotification(notif) {
      const { organizationId } = this
      this.runAsync(
        () => this.$store.dispatch('pushNotifications/deleteScheduled', { organizationId, notification: notif }),
      )
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
